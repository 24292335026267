import React from 'react'
import WildOnImg from '../assets/Wild_On_Tees.png' 

const Work = () => {
  return (
    <div name='work' className='w-full md:h-screen text-gray-300 bg-[#193082]'>
        <div className='max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full'>
            <div className='pb-8'>
                <p className='text-4xl font-bold inline border-b-4 text-gray-300 border-[#5B9A8B]'>Work</p>
                <p className='py-6'>// Check Out some of my recent work </p>
            </div>
            
            {/* Container */}
            <div className='grid sm:grid-cols-1 gap-4'>
                {/*Grid Item */}
                <div style={{backgroundImage: `url(${WildOnImg})`}} className='shadow-lg shadow-[#040c16] group container round-md flex justify-center items-center mx-auto content-div'>
                    
                    {/* Hover Effects */}
                    <div className='opacity-0 group-hover:opacity-100'>
                        <span className='text-2xl font-bold text-white tracking-wider'>
    AI powered T-shirt design generator
                        </span>
                        <div className='pt-8 text-center'>
                            <a href='https://wildoncustomtees.fun/' target='_blank'>
                                <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>Launch</button>
                            </a>
                            <a href='https://github.com/DanielDonaldson1017/wild_on_custom_tees' target='_blank'>
                                <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>Code</button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Work