import React from 'react'

import HTML from '../assets/html.png'
import CSS from '../assets/css.png'
import Javascript from '../assets/javascript.png'
import Tailwind from '../assets/tailwind.png'
import ReactLogo from '../assets/react.png'
import Nodejs from '../assets/node.png'


const Skills = () => {
  return (
    <div name='skills' className='w-full h-screen bg-[#193082] text-gray-300' >
        {/* Container */}
        <div className='max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full'>
            <div>
                <p className='text-4xl font-bold inline border-b-4 border-[#5B9A8B]'>Skills</p>
                <p className='py-4'>// These are technologies I've worked with //</p>
            </div>
        
            <div className='w-full grid grid-cols-2 sm:grid-cols-3 gap-6 text-center py-8'>
                <div className='shadow-md shadow-[#5B9A8B] hover:scale-110 duration-500'>
                    <img className='w-20 mx-auto' src={HTML} alt='HTML icon' />
                    <p className='my-3'>HTML</p>
                </div>
                <div className='shadow-md shadow-[#5B9A8B] hover:scale-110 duration-500'>
                    <img className='w-20 mx-auto' src={CSS} alt='CSS icon' />
                    <p className='my-3'>CSS</p>
                </div>                <div className='shadow-md shadow-[#5B9A8B] hover:scale-110 duration-500'>
                    <img className='w-20 mx-auto' src={Javascript} alt='JavaScript icon' />
                    <p className='my-3'>JAVASCRIPT</p>
                </div>                <div className='shadow-md shadow-[#5B9A8B] hover:scale-110 duration-500'>
                    <img className='w-20 mx-auto' src={ReactLogo} alt='React icon' />
                    <p className='my-3'>REACT</p>
                </div>                <div className='shadow-md shadow-[#5B9A8B] hover:scale-110 duration-500'>
                    <img className='w-20 mx-auto' src={Tailwind} alt='Tailwind icon' />
                    <p className='my-3'>TAILWIND</p>
                </div>                <div className='shadow-md shadow-[#5B9A8B] hover:scale-110 duration-500'>
                    <img className='w-20 mx-auto' src={Nodejs} alt='NodeJS icon' />
                    <p className='my-3'>NODEJS</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Skills